.swal2-confirm {
  box-shadow: none !important;
}

.swal2-container {
  z-index: 9999 !important;
}
.swal2-confirm {
  border-radius: 7px !important;
  min-width: 64px !important;
  line-height: 1.75 !important;
  font-size: 0.875rem !important;
  padding: 5px 15px !important;
}

.swal2-cancel {
  border: 1px solid rgba(30, 40, 61, 0.5) !important;
  color: #1e283d !important;
  border-radius: 7px !important;
  min-width: 64px !important;
  line-height: 1.75 !important;
  font-size: 0.875rem !important;
  padding: 5px 15px !important;
}

.swal2-html-container {
  margin-top: 0 !important;
  font-family: Noto Sans KR !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}
.no-border {
  border: 0 !important;
}

.swal2-title {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
  margin: 0 !important;
  font-family: Noto Sans KR;
  font-size: 1.25rem !important;
  line-height: 1.6 !important;
  font-weight: 700 !important;
}
